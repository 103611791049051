body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blog-card {
  transition: all 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px) scale(1.005);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.btn-outline-dark {
  transition: all 0.3s ease;
}

.btn-outline-dark:hover {
  background-color: #343a40;
  color: white;
}

.project-card {
  transition: all 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px) scale(1.005);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.github-button:hover {
  background-color: white !important;
  color: black !important;
}

.experience-card {
  transition: all 0.3s ease;
}

.experience-card:hover {
  transform: translateY(-5px) scale(1.005);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.experience-card:hover img {
  transform: scale(1.1);
}